@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css");
@import url("https://unpkg.com/bs-brain@2.0.4/components/facts/fact-4/assets/css/fact-4.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


/* Scrollbar Width */
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

/* Track (Background of the scrollbar) */
::-webkit-scrollbar-track {
    background: #2d3748; /* Dark gray background for the track */
    border-radius: 0px; /* Rounded corners for the track */
}

/* Handle (The draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
    background: #4a5568; /* Lighter gray for the thumb */
    border-radius: 5px; /* Rounded corners for the thumb */
    border: 2px solid #2d3748; /* Border to blend with the track */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #718096; /* Slightly lighter gray on hover */
}